<template>
  <div class="home">
    <TopUser />
    <GlobalGraph />
    <GlobalUserGraph />
    <GlobalChanGraph />
  </div>
</template>

<script>
// @ is an alias to /src
import TopUser from '@/components/TopUser.vue'
import GlobalGraph from '@/components/GlobalGraph.vue'
import GlobalUserGraph from '@/components/GlobalUserGraph.vue'
import GlobalChanGraph from '@/components/GlobalChanGraph.vue'

export default {
  name: 'Home',
  components: {
    TopUser,
    GlobalGraph,
    GlobalUserGraph,
    GlobalChanGraph
  }
}
</script>

<style lang="scss" scoped>
.home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 1140px;
  gap: 24px;
  padding: 20px;
  box-sizing: border-box;
}
</style>
