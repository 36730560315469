<template>
  <div class="top-user">
    <p class="title">Membres du jour</p>
    <div class="users">
      <div class="user" v-for="(user, index) in uotd" :key="index">
        <img :src="user.avatar" alt="member avatar">
        <span class="name">@{{ user.user_name }} - {{ user.total }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "top-user",
  data: () => ({
    users: [],
    uotd: [],
  }),
  methods: {
    fetchUsers() {
      return fetch(process.env.VUE_APP_BASE_URL + '/users')
        .then(response => response.json())
        .then(data => {
          this.users = data;
          this.fetchUsersOfTheDay().then(() => {
            this.renderRanking();
          });
        })
        .catch(error => console.error(error));
    },
    fetchUsersOfTheDay() {
      return fetch(process.env.VUE_APP_BASE_URL + '/uotd')
        .then(response => response.json())
        .then(data => {
          this.uotd = data;
        })
        .catch(error => console.error(error));
    },
    renderRanking() {
      this.uotd.forEach(elem => {
        let user = this.users.find(user => {
          return user.name === elem.user_name;
        });
        if (user) {
          elem.avatar = user.avatar;
        }
      });
    }
  },
  mounted() {
    this.fetchUsers();
  }
}
</script>

<style lang="scss" scoped>
.top-user {
  background-color: #252525;
  padding: 24px;
  box-sizing: border-box;
  border-radius: 16px;
  .title {
    text-align: left;
    font-size: 20px;
    width: 100%;
    margin-top: 0;
  }
  .users {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 12px;
    .user {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      img {
        height: 60px;
        border-radius: 50%;
      }
      .name {
        opacity: 0;
        position: absolute;
        z-index: 100;
        bottom: -20px;
        white-space: nowrap;
        transition: all .2s ease;
        color: rgb(223, 223, 223);
      }
      &:hover {
        .name {
          opacity: 1;
          transform: translateY(10px);
        }
      }
      &:first-child {
        img {
          border: 4px solid gold;
        }
      }
      &:nth-child(2) {
        img {
          border: 4px solid silver;
        }
      }
      &:nth-child(3) {
        img {
          border: 4px solid brown;
        }
      }
    }
  }
}
</style>