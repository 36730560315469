<template>
  <div class="global-chan-graph">
    <p class="title">Nombre total de messages par channel sur les 7 derniers jours</p>
    <canvas id="global-chan-graph"></canvas>
  </div>
</template>

<script>
export default {
  name: 'GlobalGraph',
  data: () => ({
    data: [],
    labels: [],
    datasets: [],
    colors: ["#f6e58d", "#ffbe76", "#ff7979", "#badc58", "#dff9fb", "#f9ca24", "#f0932b", "#eb4d4b", "#6ab04c", "#c7ecee", "#7ed6df", "#e056fd", "#686de0", "#30336b", "#22a6b3"],
    initialDataset: {
      label: '',
      data: [],
      backgroundColor: [
        // 'rgba(255, 99, 132, 0.1)',
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
      ],
      borderWidth: 3,
      // fill: true,
      tension: .5
    }
  }),
  props: {
    msg: String
  },
  methods: {
    initData() {
      const today = new Date(new Date().setDate(new Date().getDate() + 1));
      // Set 30 previous days
      for (let i = 0; i < 30; i++) {
        const date = new Date(today.setDate(today.getDate() - 1));
        const dateString = date.toISOString().split('T')[0];
        this.data.push({
          date: dateString,
          total: 0
        });
      }

      this.fetchData()
        .then(() => {
          this.drawGraph();
        }).catch(() => {
          
        })
    },
    fetchData() {
      return fetch(process.env.VUE_APP_BASE_URL + '/channels')
        .then(response => response.json())
        .then(data => {
          let index = 0;
          for (const [key, value] of Object.entries(data)) {
            const dataSet = JSON.parse(JSON.stringify(this.initialDataset));
            dataSet.label = value.channel;
            dataSet.borderColor = this.colors[index];
            dataSet.data.push(value['J-6'], value['J-5'], value['J-4'], value['J-3'], value['J-2'], value['J-1'], value['J']);
            this.datasets.push(dataSet);
            index == this.colors.length - 1 ? index = 0 : index++;
          }
        })
        .catch(error => console.error(error));
    },
    drawGraph() {
      const ctx = document.getElementById('global-chan-graph').getContext('2d')
      const myChart = new Chart(ctx, {
        type: 'line',
        data: {
          labels: ['J-6', 'J-5', 'J-4', 'J-3', 'J-2', 'J-1', 'J'],
          datasets: this.datasets
        },
        options: {
          scales: {
          },
          plugins: {
            legend: {
              labels: {
                color: "#a6a6a6",
                usePointStyle: true
              }
            }
          }
        }
      })
    }
  },
  mounted() {
    this.initData();
    // this.fetchData();
  }
}
</script>

<style lang="scss" scoped>
.global-chan-graph {
  height: fit-content;
  width: 100%;
  background-color: rgb(37, 37, 37);
  padding: 24px;
  box-sizing: border-box;
  border-radius: 16px;
  .title {
    text-align: left;
    font-size: 20px;
    width: 100%;
    margin-top: 0;
  }
}
</style>