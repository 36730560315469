<template>
  <div class="header">
    <div class="wrapper">
      <img src="./assets/pie-chart.png" />
      <span class="title">ClashClashChart</span>
    </div>
  </div>
  <router-view/>
</template>

<style lang="scss">
@import './styles/global';

#app {
  background-color: #292929;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #929292;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .header {
    height: 64px;
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: #1f1f1f;
    .wrapper {
      height: 100%;
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 16px;
      box-sizing: border-box;
      width: 1240px;
      .title {
        font-size: 24px;
      }
    }
  }
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #8799aa;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
