<template>
  <div class="global-graph">
    <p class="title">Nombre total de messages sur les 30 derniers jours</p>
    <canvas id="global-graph"></canvas>
  </div>
</template>

<script>
export default {
  name: 'GlobalGraph',
  data: () => ({
    data: [],
    labels: [],
    dataset: [],
  }),
  props: {
    msg: String
  },
  methods: {
    initData() {
      const today = new Date(new Date().setDate(new Date().getDate() + 1));
      // Set 30 previous days
      for (let i = 0; i < 30; i++) {
        const date = new Date(today.setDate(today.getDate() - 1));
        const dateString = date.toISOString().split('T')[0];
        this.data.push({
          date: dateString,
          total: 0
        });
      }

      this.fetchData()
        .then(() => {
          this.data.forEach(elem => {
            this.labels.push(elem.date);
            this.dataset.push(elem.total);
          })
          this.drawGraph();
        }).catch(() => {
          
        })
    },
    fetchData() {
      return fetch(process.env.VUE_APP_BASE_URL + '/messages')
        .then(response => response.json())
        .then(data => {
          for(const property in data) {
            let date = data[property].date;
            let node = this.data.find(elem => {
              return elem.date === date;
            });
            if(node) {
              node.total = data[property].total;
            }
          }
        })
        .catch(error => console.error(error));
    },
    drawGraph() {
      const ctx = document.getElementById('global-graph').getContext('2d')
      const myChart = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: this.labels.reverse(),
          datasets: [
            {
              label: 'Nombre de messages',
              data: this.dataset.reverse(),
              backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
              ],
              borderColor: [
                'rgba(255, 99, 132, 1)',
              ],
              borderWidth: 3,
              tension: .5
            }
          ]
        },
        options: {
          scales: {
          },
          plugins: {
            legend: {
              display: false
            } // Hide legend
          }
        }
      })
    }
  },
  mounted() {
    this.initData();
    // this.fetchData();
  }
}
</script>

<style lang="scss" scoped>
.global-graph {
  height: fit-content;
  width: 100%;
  background-color: rgb(37, 37, 37);
  padding: 24px;
  box-sizing: border-box;
  border-radius: 16px;
  .title {
    text-align: left;
    font-size: 20px;
    width: 100%;
    margin-top: 0;
  }
}
</style>